import smooth_scroll from './smooth-scroll';
import aos from './aos';

smooth_scroll();
aos();

const serviceCards = document.querySelectorAll('.service-card');
const menuBtn = document.querySelector('.menu-btn');
const menu = document.querySelector('.slide-in-menu');
const menuItems = document.querySelectorAll('.menu-item');
const body = document.querySelector('body');

const toggleMenu = () => {
    menuBtn.classList.toggle('open');
    menu.classList.toggle('open');

    if (menu.classList.contains('open')) {
        body.style.overflow = 'hidden';
    } else {
        body.style.overflow = 'visible';
    }
};

menuBtn.addEventListener('click', toggleMenu);
menuItems.forEach((item) => item.addEventListener('click', toggleMenu));

serviceCards.forEach((card, i) => {
    card.addEventListener('click', () => {
        if (card.classList.contains('selected')) {
            return card.classList.remove('selected');
        }
        serviceCards.forEach((card) => {
            card.classList.remove('selected');
        });
        card.classList.add('selected');
    });
});
