const aos = () => {
    const aosElements = document.querySelectorAll('.aos');

    const callback = (entries, observer) => {
        entries.forEach((entry) => {
            const aosType = !!entry.target.attributes['data-type']
                ? aosElements[0].attributes['data-type'].value
                : 'fade-in';
            if (entry.isIntersecting) {
                entry.target.classList.add(aosType);
            }
        });
    };
    const options = {
        rootMargin: '-100px',
    };

    const myObserver = new IntersectionObserver(callback, options);

    aosElements.forEach((element) => {
        myObserver.observe(element);
    });
};

export default aos;
