const scroll = (target, duration) => {
    const targetElement = document.querySelector(target);

    // remove snap scroll for animation
    const html = document.querySelector('html');
    html.style['scrollSnapType'] = 'none';

    //const nav = document.getElementById('navbar');
    //let navHeight = 0;
    //if (nav) {
    //    navHeight = nav.clientHeight;
    //}

    const targetPosition = targetElement.offsetTop;
    const currentPosition = window.pageYOffset;
    const distance = targetPosition - currentPosition; //- navHeight;
    let startTime = null;

    const scrollAnimation = (currentTime) => {
        if (startTime === null) {
            startTime = currentTime;
        }
        const timeElapsed = currentTime - startTime;
        const run = easeInOut(timeElapsed, currentPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(scrollAnimation);
    };

    const easeInOut = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t * t + b;
        t -= 2;
        return (c / 2) * (t * t * t + 2) + b;
    };

    /*
    function excelToZero(t, b, c, d) {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };
  
    function excelToHalf(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
      t -= 2;
      return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
    };
    */

    requestAnimationFrame(scrollAnimation);

    html.style['scrollSnapType'] = 'y mandatory';
};

const smooth_scroll = () => {
    // Smooth Scrolling
    const smoothScrollLinks = document.querySelectorAll('.smooth-scroll-link'); // add class 'smooth-scroll-link' to links

    smoothScrollLinks.forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            let href = link.attributes[0].textContent;
            if (!href.startsWith('#')) {
                href = link.attributes[1].textContent;
            }
            // event.preventDefault();
            scroll(href, 500);
        });
    });
};

export default smooth_scroll;
